import { ForgeIcon, ForgeButton, ForgeBanner } from '@tylertech/forge-react';
import React, { FunctionComponent, useState } from 'react';
import { AppState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { clearParameterOverrides, compileAndRunQuery } from '../redux/actions';
import { fetchTranslation } from 'common/locale';
import UnappliedChangesModalWrapper, { Reason as UnappliedChangesModalReason } from './UnappliedChangesModalWrapper';
import { selectors as SelectRemoteStatus } from '../redux/statuses';
import { querySuccess } from '../lib/selectors';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_banner');

const GridBanner: FunctionComponent = () => {
  const dispatch = useDispatch();
  const parameters = useSelector((state: AppState) =>
    state.clientContextInfo.variables
  );

  const fourfour = useSelector((state: AppState) => state.fourfourToQuery);
  const query = useSelector((state: AppState) => state.query);
  const remoteStatusInfo = useSelector((state: AppState) => state.remoteStatusInfo);
  const [showModal, setShowModal] = useState<UnappliedChangesModalReason | null>(null);

  const onClearOverrides = () => {
    // there are pending changes
    if (SelectRemoteStatus.applyable(remoteStatusInfo).isDefined) {
      setShowModal(UnappliedChangesModalReason.DISCARD_PARAM_OVERRIDE);
    } else if (SelectRemoteStatus.cannotRunQuery(remoteStatusInfo).isDefined) {
      setShowModal(UnappliedChangesModalReason.INVALID_SOQL);
    } else {
      dispatch(clearParameterOverrides());
      query.text.map(queryText => {
        dispatch(compileAndRunQuery(fourfour, queryText));
      });
    }
  };

  const discardChanges = () => {
    dispatch(clearParameterOverrides());
    const lastQueryText = querySuccess(query.queryResult).flatMap((qr) => qr.compiled.text);
    lastQueryText.map((text) => {
      dispatch(compileAndRunQuery(fourfour, text));
    });
    setShowModal(null);
  };

  // we only return the banner if there is at least one override
  return parameters.some((param) => param?.overrideValue) ? (
    <div>
      <ForgeBanner canDismiss={false} theme={'warning'}>
        <ForgeIcon name="warning" slot="icon" />
        <span>{t('preview_mode')}</span>
        <ForgeButton slot="button" type='outlined'>
          <button
            aria-label={t('reset_default')}
            onClick={() => onClearOverrides()}
            style={{ backgroundColor: 'var(--mdc-theme-background, #fff)' }}
            data-testid='param-overrides-reset-to-default'
          >
            <span>{t('reset_default')}</span>
          </button>
        </ForgeButton>
      </ForgeBanner>
      {showModal && <UnappliedChangesModalWrapper
        onPrimaryAction={() => setShowModal(null)}
        onDiscardChanges={() => discardChanges()}
        onDismiss={() => setShowModal(null)}
        isOpen={!!showModal}
        reason={showModal} />}
    </div>
  ) : (
    null
  );
};

export default GridBanner;
