import Dropdown from 'common/components/Dropdown';
import { connect } from 'react-redux';
import {
  AnalyzedAst,
  AnalyzedSelectedExpression,
  isColumnRef,
  isTypedFunCall,
  Expr,
  OrderBy,
  UnAnalyzedAst,
  Scope,
  SoQLType,
  isSortable,
  UnAnalyzedSelectedExpression,
  isExpressionEqualIgnoringPosition
} from 'common/types/soql';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { zipSelection, makeFilter, containsAggregate, isUsedInGroupBy } from '../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import { option, Option, none, some } from 'ts-option';
import { TableAliases, ViewContext } from 'common/types/compiler';
import ColumnTypeIcon from 'common/components/ColumnTypeIcon';
import { Tab } from 'common/explore_grid/types';
import Flyout from 'common/components/Flyout';
import { RunAST, CompileAST, ApplyChanges, DiscardChanges } from './visualContainer';
import cssesc from 'cssesc'; // polyfill for CSS.escape
import CalculatedColModal from './CalculatedColModal';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import { ContextualEventHandlers, VQEColumn } from '../redux/store';
import { ColumnUpdated, Dispatcher, storeUndoDispatchable } from '../redux/actions';
import { mapStateToProps, VisualContainerStateProps } from '../components/visualContainer';
import FirefoxDragHack from '../lib/firefoxDragHack';
import { ForgeMenu, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { IListItemComponent, IMenuOption, IconComponentDelegate, IconExternalType } from '@tylertech/forge';
import UnappliedChangesModalWrapper, {
  buildNavigationOptions, NavigateWithUnappliedChanges, Reason
} from './UnappliedChangesModalWrapper';

const t = (k: string, options = {}) => I18n.t(k, { ...options, scope: 'shared.explore_grid.grid_column_header' });

export type ColumnFormatLookup = (fieldName: string) => ColumnFormat;
export const DEFAULT_WIDTH = 250;

interface OwnProps {
  selection: AnalyzedSelectedExpression;
  scope: Scope;
  unanalyzedSelectedExpr: Option<UnAnalyzedSelectedExpression>;
  vqeColumn: Option<VQEColumn>;
  viewContext: Option<ViewContext>;
  tableAliases: TableAliases;
  position: number;
  formatLookup: ColumnFormatLookup;
  unanalyzed: UnAnalyzedAst;
  analyzedSelection: AnalyzedAst['selection'];
  runAST: RunAST;
  compileAST: CompileAST;
  openTab: (tab: Tab) => void;
  filteredColumns: string[];
  toEditColumnMetadata?: ContextualEventHandlers['editColumnMetadata'];
  toFormatColumn?: ContextualEventHandlers['formatColumn'];
  toHandleColumnWidthChange?: ContextualEventHandlers['handleColumnWidthChange'];
  applyChanges: ApplyChanges;
  discardChanges: DiscardChanges;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  modalTargetWindow: Window | null;
}

interface DispatchProps {
  columnUpdated: (updatedColumn?: VQEColumn) => void;
}

export type ColumnHeaderProps = OwnProps & DispatchProps;

interface ColumnHeaderState {
  showCalcColModal: boolean;
  showUnappliedChangesModal: boolean;
  onApplyChangesCallback: () => void;
  onDiscardChangesCallback: () => void;
  lastDrag: number | null;
  width: number | undefined;
  dragManager: FirefoxDragHack;
}

interface HeaderOption {
  title: string;
  value: () => void;
  icon?: string;
  disabled?: boolean;
  render: (h: HeaderOption) => React.ReactElement;
  group: string;
  className?: string;
  helpText?: string;
  moreLink?: boolean;
}

enum COLUMN_OPTIONS {
  filter = 'filter',
  clear_sort_asc= 'clear_sort_asc',
  sort_asc = 'sort_asc',
  clear_sort_dsc = 'clear_sort_dsc',
  sort_dsc = 'sort_dsc',
  column_order = 'column_order',
  group_and_aggregate = 'group_and_aggregate',
  rm_column = 'rm_column',
  format_column = 'format_column',
  description = 'description'
}

export class ColumnHeader extends React.Component<ColumnHeaderProps, ColumnHeaderState> {
  constructor(props: ColumnHeaderProps) {
    super(props);

    this.state = {
      showCalcColModal: false,
      showUnappliedChangesModal: false,
      onApplyChangesCallback: _.noop,
      onDiscardChangesCallback: _.noop,
      lastDrag: null,
      width: undefined,
      dragManager: new FirefoxDragHack()
    };
  }

  componentWillUnmount() {
    this.state.dragManager.cleanup();
  }

  existingSort = (): Option<OrderBy> => {
    return this.props.unanalyzedSelectedExpr.flatMap(selectedExpr => {
      return option(this.props.unanalyzed.order_bys.find(ob => (
        // this finds order bys that are just exprs, like `select foo + 1 order by foo + 1`
        isExpressionEqualIgnoringPosition(ob.expr, selectedExpr.expr) ||
        // this finds order bys that refer to aliases, like `select foo + 1 as whatever order by whatever`
        isColumnRef(ob.expr) && ob.expr.value === this.props.selection.name
      )));
    });
  };

  addOrReplaceSort = (ascending: boolean) => {
    const newOrderBys: OrderBy[] = this.existingSort().match({
      none: () => {
        // we will tack the OrderBy onto the end of the existing ones
        let qualifier = null;
        const expr = this.props.selection.expr;
        if (isColumnRef(expr)) {
          qualifier = expr.qualifier;
        }


        return [...this.props.unanalyzed.order_bys , {
          ascending,
          null_last: true,
          expr: {
            type: 'column_ref',
            value: this.props.selection.name,
            qualifier
          }
        }];
      },
      some: (existing) => {
        // we will replace the existing OB with the new ascending/descending
        return this.props.unanalyzed.order_bys.map(ob => {
          const expr = ob.expr;
          if (isColumnRef(expr) && expr.value === this.props.selection.name) {
            return { ...ob, ascending };
          } else {
            return ob;
          }
        });
      }
    });

    this.props.runAST({
      ...this.props.unanalyzed,
      order_bys: newOrderBys
    }, none);
  };

  clearSort = () => {
    this.existingSort().map(existing => {
      const ast = this.props.unanalyzed;
      this.props.runAST({
        ...ast,
        order_bys: ast.order_bys.filter(ob => !_.isEqual(ob, existing))
      }, none);
    });
  };

  onClickRemove = () => {
    if (isColumnRef(this.props.selection.expr)) {
      this.removeColumn();
    } else {
      this.setState({ showCalcColModal: true });
    }
  };

  removeColumn = () => {
    this.setState({ showCalcColModal: false });
    this.props.viewContext.forEach(viewContext => {
      const { tableAliases, unanalyzed, analyzedSelection } = this.props;

      const newSelection = zipSelection(
        viewContext,
        tableAliases,
        unanalyzed.selection,
        analyzedSelection
      );
      this.props.runAST({
        ...unanalyzed,
        selection: {
          ...newSelection,
          exprs: newSelection.exprs.filter((_expr, i) => i !== this.props.position)
        }
      }, none);
    });
  };

  filter = () => {
    const { unanalyzed, compileAST, openTab, selection, scope, unanalyzedSelectedExpr, remoteStatusInfo } = this.props;
    const { expr } = selection; // TypedExpr
    const whereType = expr.soql_type || SoQLType.SoQLTextT;

    /* If the column to be filtered is an aliased calculated column, then
     * create a column ref for reference when filtered. */
    const untypedExpr = unanalyzedSelectedExpr.flatMap(use => {
      if (isColumnRef(use.expr)) return none; // Not a calculated column
      if (_.isNull(use.name)) return none; // Not an aliased calculated column
      return some({
        type: 'column_ref',
        value: selection.name,
        qualifier: null
      } as Expr);
    }).getOrElseValue((({soql_type, ...untyped}) => untyped)(expr) as Expr);

    const isAggregated = isTypedFunCall(expr) && containsAggregate(scope, (({soql_type, ...untyped}) => untyped)(expr));
    const isGrouped = isUsedInGroupBy(selection.name, untypedExpr, unanalyzed);

    const where = () => makeFilter(untypedExpr, whereType, unanalyzed.where);
    const having = () => makeFilter(untypedExpr, whereType, unanalyzed.having);

    // There's little sense in modifying the query if changes have been compiled without running.
    if (SelectRemoteStatus.queryRanSuccessfully(remoteStatusInfo).isDefined) {
      if (isAggregated || isGrouped) {
        compileAST({ ...unanalyzed, having: having()}, true);
      } else {
        compileAST({ ...unanalyzed, where: where()}, true);
      }
    }

    openTab(Tab.Filter);
  };

  order = () => {
    this.props.openTab(Tab.ColumnManager);
  };

  group = () => {
    this.props.openTab(Tab.Aggregate);
  };

  format = () => {
    this.props.vqeColumn.forEach((col) => {
      this.navigateWithUnappliedChanges(() => {
        this.props.toFormatColumn!(col, this.props.columnUpdated);
      }, { navigateOnApply: true });
    });
  };

  description = () => {
    if (this.props.toEditColumnMetadata && this.props.vqeColumn.isDefined) {
      this.navigateWithUnappliedChanges(() => {
        this.props.toEditColumnMetadata!(COLUMN_OPTIONS.description, this.props.vqeColumn.get);
      });
    }
  };

  navigateWithUnappliedChanges: NavigateWithUnappliedChanges = (navigate, partialOptions = {}) => {
    const options = buildNavigationOptions(partialOptions);
    const shouldDiscardChanges = SelectRemoteStatus.discardChangesOnTabChange(this.props.remoteStatusInfo).isDefined;
    const shouldOpenModal = SelectRemoteStatus.applyable(this.props.remoteStatusInfo).isDefined;

    const navigateOnApply = options.navigateOnApply ? navigate : _.noop;
    const navigateOnDiscard = options.navigateOnDiscard ? navigate : _.noop;

    if (shouldDiscardChanges) {
      this.setState({ showUnappliedChangesModal: false });
      this.props.discardChanges();
      navigate();
    } else if (shouldOpenModal) {
      this.setState({
        showUnappliedChangesModal: true,
        onDiscardChangesCallback: navigateOnDiscard,
        onApplyChangesCallback: navigateOnApply
      });
    } else {
      navigate();
    }
  };

  onApplyChanges = () => {
    this.setState({
      showUnappliedChangesModal: false,
      onApplyChangesCallback: _.noop,
      onDiscardChangesCallback: _.noop
    });
    this.props.applyChanges(none);
    this.state.onApplyChangesCallback();
  };

  onDiscardChanges = () => {
    this.setState({
      showUnappliedChangesModal: false,
      onApplyChangesCallback: _.noop,
      onDiscardChangesCallback: _.noop
    });
    this.props.discardChanges();
    this.state.onDiscardChangesCallback();
  };

  updateColumnWidth() {
    const newColumn = {
      ...this.props.vqeColumn.get,
      width: this.state.width
    };
    this.props.toHandleColumnWidthChange!(newColumn, () => this.columnWidthCallback(newColumn));
  }

  columnWidthCallback(newColumn: VQEColumn) {
    this.setState({ width: undefined });
    this.props.columnUpdated(newColumn);
  }

  getWidth(): number {
    return this.state.width || this.props.formatLookup(this.props.selection.name).width || DEFAULT_WIDTH;
  }

  onDragWidthHandleStart = (event: any) => {
    // this is to make the default drag and drop ghost element
    // not show up. there's no way to do it in CSS (without also
    // disabling the drag and drop behavior entirely) so this is
    // the only way to achieve what we want.
    const empty = document.createElement('canvas');
    empty.width = 0;
    empty.height = 0;
    if (event.dataTransfer.setDragImage) {
      event.dataTransfer.setDragImage(empty, 0, 0);
    }

    this.state.dragManager.onStart(event);
    const pageX = this.state.dragManager.getX(event.pageX);
    if (!_.isNumber(pageX)) return;
    this.setState({ lastDrag: pageX });
  };

  onDragWidthHandle = (maybePageX: number) => {
    const pageX = this.state.dragManager.getX(maybePageX);
    // it's not actually 0, the browser is lying to us
    if (pageX === 0) return;
    if (_.isNumber(this.state.lastDrag) && pageX !== 0) {
      const dw = pageX - this.state.lastDrag;
      this.setState({ lastDrag: pageX });
      this.setState({ width: Math.max(80, this.getWidth() + dw) });
    } else {
      this.setState({ lastDrag: pageX });
    }
  };

  onDragWidthHandleEnd = () => {
    this.setState({ lastDrag: null });
    this.updateColumnWidth();
    this.state.dragManager.onEnd();
  };

  getHelperText(column: COLUMN_OPTIONS) {
    const sortDisabled = option(this.props.selection.expr.soql_type).map(st => !isSortable(st)).getOrElseValue(true);
    const soqlDataType = this.props.selection.expr.soql_type;
    switch (column) {
      case COLUMN_OPTIONS.sort_dsc: case COLUMN_OPTIONS.sort_asc:
        return sortDisabled ? t('cannot_sort', {soqlDataType: soqlDataType} ) : undefined;
      case COLUMN_OPTIONS.rm_column:
        return this.props.analyzedSelection.length <= 1 ? t('last_column_help') : t('rm_column_help');
    }
  }

  // This is used to create the titles and subtitles in the menu
  // note since we are using the optionBuilder we have to actually build all the options in the menu.
  optionBuilder = (menuOption: IMenuOption, listItemEl: IListItemComponent) => {
    // helper func to append elements with casting as forge is a bit weird with its types
    const appendElement = (el: any) => (listItemEl as unknown as HTMLElement).appendChild(el);
    if (!menuOption.value) {
      // title section - static with no value
      const subtitleEl = document.createElement('span');
      subtitleEl.slot = 'subtitle';
      subtitleEl.textContent = menuOption.label;
      listItemEl.static = true;
      appendElement(subtitleEl);
    } else {
      // regular elements
      const titleEl = document.createElement('span');
      titleEl.slot = 'title';
      titleEl.textContent = menuOption.label;
      const optionId = 'column-list-option-' + menuOption.value;
      titleEl.id = optionId;

      // add test id
      titleEl.setAttribute('data-testid', optionId);
      listItemEl.wrap = true;

      // only apply to description
      if (menuOption.value === COLUMN_OPTIONS.description) {
        titleEl.classList.add('column-header-menu-description');
      }

      appendElement(titleEl);

      const helperText = this.getHelperText(menuOption.value);

      if (helperText) {
        const tooltip = document.createElement('forge-tooltip');
        tooltip.text = helperText;
        tooltip.position = 'bottom';
        appendElement(tooltip);
      }
    }
  };

  renderDropDown(options: IMenuOption[]) {
    return (
      <ForgeMenu
        options={options}
        placement='bottom-end'
        optionBuilder={this.optionBuilder}
        dense={true}
        popupClasses="column-header-menu-width"
        on-forge-menu-select={(v: CustomEvent) => this.handleMenuSelect(v.detail.value)}
      >
        <ForgeIconButton>
          <button
            type="button"
            data-testid="expand-collapse-bar-collapse-btn"
            className="tyler-icons"
            aria-label={t('column_options')}
          >
            <ForgeIcon name="menu" />
          </button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  }

  handleMenuSelect(action: COLUMN_OPTIONS) {
    switch (action) {
      case COLUMN_OPTIONS.filter: {
        this.filter();
        break;
      }
      case COLUMN_OPTIONS.clear_sort_asc: {
        this.clearSort();
        break;
      }
      case COLUMN_OPTIONS.sort_asc: {
        this.addOrReplaceSort(true);
        break;
      }
      case COLUMN_OPTIONS.clear_sort_dsc: {
        this.clearSort();
        break;
      }
      case COLUMN_OPTIONS.sort_dsc: {
        this.addOrReplaceSort(false);
        break;
      }
      case COLUMN_OPTIONS.column_order: {
        this.order();
        break;
      }
      case COLUMN_OPTIONS.group_and_aggregate: {
        this.group();
        break;
      }
      case COLUMN_OPTIONS.rm_column: {
        this.onClickRemove();
        break;
      }
      case COLUMN_OPTIONS.format_column: {
        this.format();
        break;
      }
      case COLUMN_OPTIONS.description: {
        this.description();
        break;
      }
    }
  }

  render() {
    const props = this.props;
    const { showCalcColModal, showUnappliedChangesModal } = this.state;

    const hoverToEdit = (metadataType: 'fieldName' | 'name' | 'description', column: Partial<ViewColumn>): JSX.Element | null => {
      if (!props.toEditColumnMetadata) return null;

      const onClick = (evt: React.SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
        evt.preventDefault();
        this.navigateWithUnappliedChanges(() => { props.toEditColumnMetadata!(metadataType, column); });
      };
      return (<div className="hover-to-edit"><a href="#" onClick={onClick} title={t('click_to_edit_metadata')}>
        {<SocrataIcon name={IconName.Edit} />}
      </a></div>);
    };

    const sortDisabled = option(props.selection.expr.soql_type).map(st => !isSortable(st)).getOrElseValue(true);
    const columnDescription = this.props.vqeColumn
      .flatMap((c: VQEColumn) => option(c.description))
      .filter((desc: string) => desc.length > 0)
      .getOrElseValue(t('no_description'));

    let sorted = 'unsorted';
    this.existingSort().forEach(ob => {
      if (ob.ascending) {
        sorted = 'ascending';
      } else {
        sorted = 'descending';
      }
    });

    const columnMenuIcons = {
      [COLUMN_OPTIONS.filter]: { iconName: 'filter' },
      [COLUMN_OPTIONS.clear_sort_asc]: { iconName: 'arrow_upward' },
      [COLUMN_OPTIONS.sort_asc]: { iconName: 'arrow_upward' },
      [COLUMN_OPTIONS.clear_sort_dsc]: { iconName: 'arrow_downward' },
      [COLUMN_OPTIONS.sort_dsc]: { iconName: 'arrow_downward' },
      [COLUMN_OPTIONS.column_order]: { iconName: 'compare_horizontal' },
      [COLUMN_OPTIONS.group_and_aggregate]: { iconName: 'group' },
      [COLUMN_OPTIONS.rm_column]: { iconName: 'remove_circle' },
      [COLUMN_OPTIONS.format_column]: { iconName: 'playlist_edit'} ,
      [COLUMN_OPTIONS.description]: { iconName: 'edit' }
    };

    const getColumnIcon = (columnOption: COLUMN_OPTIONS): HTMLElement => {
      const iconProps = {
        name: columnMenuIcons[columnOption].iconName,
      };
      // The forge stuff is a bit misleading with its types so doing a cast here to avoid TS complaining
      return (new IconComponentDelegate({ props: iconProps }).element as unknown as HTMLElement);
    };

    const forgeOptions: IMenuOption[] = [
      {
        label: t('filter'),
        value: COLUMN_OPTIONS.filter,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.filter)
      },
      {
        label: t(sorted === 'ascending' ? 'clear_sort_asc' : 'sort_asc'),
        value: sorted === 'ascending' ? COLUMN_OPTIONS.clear_sort_asc : COLUMN_OPTIONS.sort_asc,
        disabled: sortDisabled,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.clear_sort_asc)
      },
      {
        label: t(sorted === 'descending' ? 'clear_sort_dsc' : 'sort_dsc'),
        value: sorted === 'descending' ? 'clear_sort_dsc' : 'sort_dsc',
        disabled: sortDisabled,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.sort_dsc)
      },
      {
        label: t('column_order'),
        value: COLUMN_OPTIONS.column_order,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.column_order)
      },
      {
        label: t('group_and_aggregate'),
        value: COLUMN_OPTIONS.group_and_aggregate,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.group_and_aggregate)
      },
      {
        label: t('rm_column'),
        value: COLUMN_OPTIONS.rm_column,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.rm_column),
        disabled: props.analyzedSelection.length <= 1,
      },
      {
        label: t('format_column'),
        value: COLUMN_OPTIONS.format_column,
        leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.format_column),
        disabled: !this.props.toFormatColumn,
      },
      { label: '', value: null, divider: true },
      {
        label: t('description'),
        value: null,
      },
      {
        label: columnDescription,
        value: COLUMN_OPTIONS.description,
        disabled: !this.props.toEditColumnMetadata,
        // only assign leading builder property when toEditColumnMetadata is true
        ...(this.props.toEditColumnMetadata && {leadingBuilder: () => getColumnIcon(COLUMN_OPTIONS.description)})
      },
      // Remove Column Formatting if it is disabled
    ].filter(headerOption => headerOption.value !== COLUMN_OPTIONS.format_column || !headerOption.disabled);

    let sortIcon = null;

    if (sorted === 'ascending') {
      sortIcon = <ForgeIcon className='header-icons-non-interactive' name='arrow_upward' />;
    } else if (sorted === 'descending') {
      sortIcon = <ForgeIcon className='header-icons-non-interactive' name='arrow_downward' />;
    }

    const name = props.vqeColumn.map(vc => vc.name).getOrElseValue(props.selection.name);
    const fieldName = props.vqeColumn.map(vc => vc.fieldName).getOrElseValue(props.selection.name) || '';

    const filterIcon = this.props.filteredColumns.includes(fieldName)
      ? <ForgeIcon className='header-icons-non-interactive' name='filter_list_alt'/>
      : null;

    const width = this.getWidth();
    const allowDragAndDrop = this.props.toHandleColumnWidthChange && this.props.vqeColumn.isDefined;

    return (
      <th className={`grid-column-header column-header-${props.selection.name}`}
        style={{ width }}
        key={props.selection.name}>
        <div className='grid-column-header-contents-wrapper'>
          <div className="grid-column-header-contents">
            <div className="header-type-and-names">
              <ColumnTypeIcon type={props.selection.expr.soql_type} forge={true}/>
              <div className="header-names">
                <div className="column-display-name">
                  {name}
                </div>
                <div className="column-field-name">{fieldName}</div>
                {props.vqeColumn.map((col) => hoverToEdit('name', col)).getOrElseValue(null)}
              </div>
            </div>
            <div className="header-icons-and-menu">
              {sortIcon}
              {filterIcon}
              {this.renderDropDown(forgeOptions)}
            </div>
          </div>
        </div>
        {showUnappliedChangesModal && <UnappliedChangesModalWrapper
            onPrimaryAction={() => this.onApplyChanges()}
            onDiscardChanges={() => this.onDiscardChanges()}
            onDismiss={() => this.setState({ showUnappliedChangesModal: false })}
            isOpen={showUnappliedChangesModal}
            reason={Reason.TAB} />}
        {showCalcColModal && <CalculatedColModal
          onApply={this.removeColumn}
          onDismiss={() => this.setState({ showCalcColModal: false })}
          excludedColumns={[props.selection]}
          tab={Tab.ColumnManager}
        />}
        {allowDragAndDrop && <div
          draggable="true"
          className="drag-handle"
          onDragStart={this.onDragWidthHandleStart}
          onDragEnd={this.onDragWidthHandleEnd}
          onDrag={(event) => this.onDragWidthHandle(event.pageX)}/>}
      </th>
    );

  }
}

const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  return {
    columnUpdated: (updatedColumn?:VQEColumn) => {
      if (updatedColumn) dispatch(ColumnUpdated(updatedColumn));
      dispatch(storeUndoDispatchable());
    }
  };
};

const mergeProps = (stateProps: VisualContainerStateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ColumnHeader);
