import { fetchTranslation } from 'common/locale';
import { View } from 'common/types/view';
import { Scope } from 'common/types/soql';
import { FeatureFlags } from 'common/feature_flags';
import { isSoQLBased, isPublished } from 'common/views/view_types';
import { AppState, OpenModalType, Query, UndoRedo } from '../redux/store';
import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import '../styles/grid-ribbon.scss';
import { none, Option } from 'ts-option';
import { ForgeIcon, ForgeTooltip, ForgeIconButton, ForgeButton, ForgeToolbar } from '@tylertech/forge-react';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import UnappliedChangesModalWrapper, { Reason as UnappliedChangesModalReason } from './UnappliedChangesModalWrapper';
import { querySuccess } from '../lib/selectors';
import { Tab } from 'common/explore_grid/types';
import * as VisualContainer from './visualContainer';
import _ from 'lodash';
import ExportModal from './ExportModalContainer/ExportModalContainer';
import SearchView from './SearchView';
import { ExternalProps } from './visualContainer';
import { isCompilationSucceeded, QueryCompilationSucceeded } from 'common/types/compiler';
import { assign as windowLocationAssign } from 'common/window_location';
import optionallyLocalizeUrls
  from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_ribbon');

export type GridRibbonProps = GridRibbonDispatchProps &
  GridRibbonStateProps &
  GridRibbonExternalProps &
  VisualContainer.ExternalProps &
  VisualContainer.VisualContainerProps;

type GridRibbonExternalProps = {
  returnToLinkType: ReturnToLink;
  onBackTo?: () => void;
  revisionSeq?: number;
  fourfour?: string;
};

type GridRibbonDispatchProps = {
  openModalSavedQueryModal: () => void;
  undo: (viewId: string) => void;
  redo: (viewId: string) => void;
  buildDiscardChanges: (lastQueryText: Option<string>) => () => void;
};

type GridRibbonStateProps = {
  showSavedQueryButton: boolean;
  viewId: string;
  fourfourToQuery: string;
  undoEnabled: boolean;
  redoEnabled: boolean;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  query: Query;
  currentTab: Tab;
  scope: Scope;
};

export enum ReturnToLink {
  PRIMER_AND_GRID = 'PRIMER_AND_GRID',
  GRID = 'GRID',
  DSMUI = 'DSMUI',
  NONE = 'NONE'
}

// can't go to primer if unpublished (no primer)
// can't go to grid view if sowl

export const getReturnToLinkType = (v: View) => {
  // unpublished things have no primer
  // grid is mostly useless for ODP, but people want to compare/contrast
  // there are some useful things, like the row display style and comments/ratings
  if (isPublished(v)) {
    return ReturnToLink.PRIMER_AND_GRID;
  }

  // ODP sites have no interface for editing SoQL on grid, so don't send them there for unpublished soql things
  if (!isSoQLBased(v) || FeatureFlags.valueOrDefault('strict_permissions', false)) {
    return ReturnToLink.GRID;
  }

  // otherwise, nowhere for us to send people
  // no primer because not published
  // no grid because its unpublished and soql view on an ODP, where there's no soql editor
  return ReturnToLink.NONE;
};

export class GridRibbon extends React.Component<GridRibbonProps> {
  state = {
    isModalOpen: false,
    undoOrRedo: UndoRedo.UNDO,
    isExportModalOpen: false
  };

  savedQueryButton() {
    return (
      <span slot="end">
        <ForgeButton type="outlined" className="show-saved-query">
          <button type="button"
            onClick={this.props.openModalSavedQueryModal}
            id="show-saved-query-btn"
          >
            {t('saved_query')}
          </button>
        </ForgeButton>
      </span>
    );
  }

  currentTab = (): Tab => {
    const current = this.props.currentTab;

    /* Default to the filter tab when the location at
     * the end of the URL is not recognized so that it is
     * consistent with where /explore first takes the user. */
    if (!_.includes(Tab, current)) {
      return Tab.Filter;
    }

    return current;
  };

  setUnappliedChangesModalOpen = (open: boolean) => {
    this.setState({ isModalOpen: open });
  };

  showUnappliedChangesModal = () => {
    this.setState({ isModalOpen: true });
  };

  hideUnappliedChangesModal = () => {
    this.setState({ isModalOpen: false });
  };

  hideExportModal = () => {
    this.setState({ isExportModalOpen: false });
  };

  onDiscardChanges = () => {
    const lastQueryText = querySuccess(this.props.query.queryResult).flatMap((qr) => qr.compiled.text);
    const discardChanges = this.props.buildDiscardChanges(lastQueryText);
    this.hideUnappliedChangesModal();
    discardChanges();
    this.performUndoRedo(this.props.fourfourToQuery, this.state.undoOrRedo);
  };

  performUndoRedo = (fourfourToQuery: string, undoOrRedo: UndoRedo) => {
    if (undoOrRedo === UndoRedo.UNDO) {
      this.props.undo(fourfourToQuery);
    } else {
      this.props.redo(fourfourToQuery);
    }
  };

  onUndoRedoClick = (fourfourToQuery: string, undoOrRedo: UndoRedo) => {
    const shouldOpenModal = SelectRemoteStatus.applyable(this.props.remoteStatusInfo).isDefined;
    this.setState({
      undoOrRedo: undoOrRedo
    });

    if (shouldOpenModal) {
      this.showUnappliedChangesModal();
    } else {
      this.performUndoRedo(fourfourToQuery, undoOrRedo);
    }
  };

  undoRedo() {
    return (
      <span slot="end" className="undo-redo ribbon-button">
        <ForgeIconButton>
          <button
            type="button"
            aria-label={'undo'}
            onClick={() => {
              this.onUndoRedoClick(this.props.fourfourToQuery, UndoRedo.UNDO);
            }}
            disabled={!this.props.undoEnabled}
            className="undo-button"
          >
            <ForgeIcon name='undo'/>
            <ForgeTooltip>{t('undo_tooltip')}</ForgeTooltip>
          </button>
        </ForgeIconButton>
        <ForgeIconButton>
          <button
            type="button"
            aria-label={'redo'}
            disabled={!this.props.redoEnabled}
            onClick={() => {
              this.onUndoRedoClick(this.props.fourfourToQuery, UndoRedo.REDO);
            }}
            className="redo-button"
          >
            <ForgeIcon name='redo'/>
            <ForgeTooltip>{t('redo_tooltip')}</ForgeTooltip>
          </button>
        </ForgeIconButton>
      </span>
    );
  }

  communityCreatedLabel() {
    const shouldShowCommunityMessage = FeatureFlags.value('disable_authority_badge') == 'none' ||
      FeatureFlags.value('disable_authority_badge') == 'official2';

    if (shouldShowCommunityMessage && window.initialState?.view?.provenance == 'community') {
      return (
        <>
          <span slot="start"><ForgeIcon className='community-created-icon' name="account_group"/> </span>
          <span slot="start">
            <span className="community-created">
              {t('community_label')}
            </span>
            <ForgeTooltip delay={100}>{t('community_label_tooltip')}</ForgeTooltip>
          </span>
        </>
      );
    } else {
      return null;
    }
  }

  inDatasetSearch() {
    return <span slot="end" className="search-view ribbon-button">
        <SearchView {...this.props}/>
      </span>;
  }

  displayExportButton() {
    return (
      <span slot="end" id="grid-ribbon-export-button">
        <ForgeButton type="outlined"
          className="export-button">
          <button type="button"
            onClick={() => this.onDisplayExportModal()}
            disabled={this.props.query.compilationResult.map(cr => !isCompilationSucceeded<QueryCompilationSucceeded>(cr)).getOrElseValue(true)}
          >
            {t('export')}
          </button>
        </ForgeButton>
      </span>
    );
  }

  // on DSMP, show return to View Manager
  // there's a "Back to Primer"
  // if it's not odp, switch to grid shows up
  returnToLink() {
    const {viewId, onBackTo, returnToLinkType} = this.props;
    if (returnToLinkType === ReturnToLink.DSMUI && onBackTo === undefined) {
      // don't do this; but its better than blowing up the app
      return null;
    }
    const gridLink = (
      <span className="return-to-grid-link">
        <ForgeButton className="return-to-link">
          <button type="button" onClick={(() => windowLocationAssign(optionallyLocalizeUrls(`/d/${viewId}/data`)) )}>
            <ForgeIcon name="swap_horiz"/>
            {t('switch_to_gridview')}
          </button>
        </ForgeButton>
      </span>
    );
    switch (returnToLinkType) {
      case ReturnToLink.PRIMER_AND_GRID:
        return (
          <span>
            <span className="return-to-primer-link">
              <ForgeButton className="return-to-link">
                <button onClick={(() => windowLocationAssign(optionallyLocalizeUrls(`/d/${viewId}`)) )}>
                  <ForgeIcon name="arrow_back" />
                  {t('back_to_primer')}
                </button>
              </ForgeButton>
            </span>
            {gridLink}
          </span>
        );
      case ReturnToLink.GRID:
        return <span>{gridLink}</span>;
      case ReturnToLink.DSMUI:
        return (
          <span className="return-to-dsmui-link">
            <ForgeButton onClick={onBackTo} id="return-to-dsmui-link" className="return-to-link">
              <button>
                <ForgeIcon name="arrow_back"/>
                {t('go_to_view_manager')}
              </button>
            </ForgeButton>
          </span>
        );
      case ReturnToLink.NONE:
        return null;
    }
  }

  onDisplayExportModal() {
    this.setState({ isExportModalOpen: true });
  }

  render() {
    const { isModalOpen, undoOrRedo, isExportModalOpen } = this.state;
    const customModalOption = undoOrRedo === UndoRedo.REDO ? UnappliedChangesModalReason.REDO : UnappliedChangesModalReason.UNDO;

    return (
      <div className="grid-ribbon">
        <ForgeToolbar>
          <span slot="start">
            {this.returnToLink()}
          </span>
            {this.communityCreatedLabel()}
            {this.undoRedo()}
            {this.inDatasetSearch()}
            {this.displayExportButton()}
            {this.props.showSavedQueryButton && this.savedQueryButton()}
        </ForgeToolbar>
        {isModalOpen && <UnappliedChangesModalWrapper
          onPrimaryAction={this.hideUnappliedChangesModal}
          onDiscardChanges={this.onDiscardChanges}
          onDismiss={this.hideUnappliedChangesModal}
          isOpen={isModalOpen}
          reason={customModalOption}/>}
        {isExportModalOpen &&
          <ExportModal
            bodyText={fetchTranslation('body', 'shared.explore_grid.export_dataset_modal')}
            revisionSeq={this.props.revisionSeq}
            fourfour={this.props.fourfour}
            onDismiss={this.hideExportModal}
            isTableViz={false}
          />}
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState, props: ExternalProps): GridRibbonStateProps & VisualContainer.VisualContainerStateProps => {
  const { parentInfo, view, fourfourToQuery } = state;

  return {
      ...VisualContainer.mapStateToProps(state, props),
      showSavedQueryButton: !state.editing && parentInfo.canReadFromAllParents && view.queryString !== undefined && parentInfo.mainParent.isDefined,
      viewId: view.id,
      fourfourToQuery,
      undoEnabled: state.undoRedoInfo.undo.length !== 0,
      redoEnabled: state.undoRedoInfo.redo.length !== 0,
      remoteStatusInfo: state.remoteStatusInfo,
      query: state.query,
      currentTab: state.locationParams.tab,
      scope: state.scope.getOrElseValue([]),
  };
};

const mapDispatchToProps = (dispatch: Actions.Dispatcher): GridRibbonDispatchProps & VisualContainer.VisualContainerDispatchProps => {
  return {
    ...VisualContainer.mapDispatchToProps(dispatch),
    openModalSavedQueryModal: () => {
      dispatch(Actions.openModal(OpenModalType.SAVED_QUERY, none));
    },
    undo: (viewId) => dispatch(Actions.doUndo(viewId)),
    redo: (viewId) => dispatch(Actions.doRedo(viewId)),
    buildDiscardChanges: (lastQueryText: Option<string>) => () => {
      lastQueryText.map((text) => {
        dispatch(Actions.setQueryText(text));
        dispatch(Actions.compileText(text));
      });
    }
  };
};

const mergeProps = (
  state: GridRibbonStateProps & VisualContainer.VisualContainerStateProps,
  disp: GridRibbonDispatchProps & VisualContainer.VisualContainerDispatchProps,
  ownProps: VisualContainer.ExternalProps & GridRibbonExternalProps): GridRibbonProps => {
  return {
    ...ownProps,
    ...VisualContainer.mergeProps(state, disp, ownProps)
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GridRibbon);
