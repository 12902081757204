import {
  AnalyzedSelectedExpression,
  ColumnRef,
  Expr,
  isColumnRef,
  isExpressionEqualIgnoringPosition,
  Scope,
  TypedExpr,
  UnAnalyzedAst,
  UnAnalyzedSelectedExpression,
  SoQLType,
  FunCall,
  FunSpec,
  isFunCall,
  isFunCallEqualIgnoringPosition
} from 'common/types/soql';
import { ProjectionInfo, ViewColumnColumnRef } from '../../lib/selectors';
import { replaceAt } from 'common/util';
import { PickableColumn } from '../../lib/column-picker-helpers';
import { containsAggregate, containsNonAggregatedExpr, hasGroupBys, isAggregateCall, isSubExpr, pluckColumnRefs } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import { Option, option, some, none } from 'ts-option';
import ExpressionEditor, { isEditable } from '../VisualExpressionEditor';
import { putGroupingInSelection, dropOrderBys, buildSelection } from '../../components/VisualGroupAggregateEditor';
import { CompileAST } from '../visualContainer';
import SubtitleWithHelper from '../SubtitleWithHelper';
import { fetchTranslation } from 'common/locale';
import ColumnPicker from '../ColumnPicker';
import RemoveNode from '../RemoveNode';
import { getFilteredColumnNames } from '../GridTable';
import RemoveGroupModal from './RemoveGroupModal';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import AggregateFunPicker from '../AggregateAddExpr/AggregateFunPicker';
import { Operator } from '../visualNodes/Types';
import { EditableExpression, Eexpr } from 'common/explore_grid/types';
import { FeatureFlags } from 'common/feature_flags';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_group_bys');

interface VisualGroupByProps {
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  group: Eexpr<Expr, TypedExpr>;
  projectionInfo: ProjectionInfo;
  querySucceeded: boolean;
  scope: Scope;
  onUpdate: (newExpr: Expr) => void;
  onRemove: () => void;
}

const enableSimpleDateGrouping = FeatureFlags.value('enable_simple_date_grouping_ec');

const dateTruncationFunctions = ['date_trunc_y', 'date_trunc_ym', 'date_trunc_ymd'];
const dateTruncationFunctionsFixedTimestamp = ['datez_trunc_y', 'datez_trunc_ym', 'datez_trunc_ymd'];

const isDateLike = (type?: SoQLType): boolean => {
  if (type) return (
    [
      SoQLType.SoQLFixedTimestampT,
      SoQLType.SoQLFixedTimestampAltT,
      SoQLType.SoQLFloatingTimestampT,
      SoQLType.SoQLFloatingTimestampAltT
    ].includes(type)
  );
  return false;
};

const shouldShowGroupByFunctionPicker = (eexpr: Eexpr<Expr, TypedExpr>): boolean => {
  if (enableSimpleDateGrouping && isEditable(eexpr)) {
    return isColumnRef(eexpr.typed) && isDateLike(eexpr.typed.soql_type);
  } else {
    return false;
  }
};

const getAnyAliasName = (expression: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>) => {
  return expression.untyped.name?.name;
};

const getValidHaving = (selectionsToKeep: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], groupBys: Expr[], having: Expr | null, scope: Scope) => {
  // if there are no group bys you don't get to have any having!
  if (!groupBys.length) return null;

  // split out colRefs and funCalls from group by
  const groupByColReferences: ColumnRef[] = [];
  const groupByFunCallReferences: FunCall[] = [];
  const selectionAliases: string[] = [];

  groupBys.forEach(gb => {
    if (isColumnRef(gb)) {
      groupByColReferences.push(gb);
    } else if (isFunCall(gb)) {
      groupByFunCallReferences.push(gb);
    }
  });

  selectionsToKeep.forEach(sel => {
    const maybeAlias = getAnyAliasName(sel);
    if (maybeAlias) selectionAliases.push(maybeAlias);
  });

  const groupByContainsColumnRef = (colRef: ColumnRef): boolean => {
    if (_.some(groupByColReferences, gb => _.isEqual(gb.value, colRef.value))) return true;
    // check aliases if its not in the column refs
    return _.some(selectionAliases, alias => _.isEqual(alias, colRef.value));
  };

  const groupByContainsFunCall = (funCall: FunCall) => _.some(groupByFunCallReferences, fc => isFunCallEqualIgnoringPosition(fc, funCall));

  const validateHavingExpr = (havingExpr?: Expr | null): Expr | null => {
    if (!havingExpr) return null;

    if (isColumnRef(havingExpr)) {
      return groupByContainsColumnRef(havingExpr) ? havingExpr : null;
    } else if (isFunCall(havingExpr)) {
      // aggregate functions do not need to exist in the group by
      if (isAggregateCall(scope, havingExpr)) return havingExpr;

      if (groupByContainsFunCall(havingExpr)) {
        return havingExpr;
      } else {
        // if we are in an AND or OR we can selectively delete chunks of the having that are not valid
        if (havingExpr.function_name === Operator.AND || havingExpr.function_name === Operator.OR) {
          // we filter out any invalid EXPR
          const validArgs = havingExpr.args.map((v) => validateHavingExpr(v)).filter(arg => arg !== null);
          if (validArgs.length === 0) return null;
          if (validArgs.length === 1) return validArgs[0];
          return {
            ...havingExpr,
            args: validArgs as Expr[]
          };
        } else {
          // We are not in an AND or OR, so either this chunk of the expr is valid or we remove it altogether
          const exprArgs = havingExpr.args.map((v) => validateHavingExpr(v));
          // only return these exprs if the array has no invalid exprs (null values)
          if (exprArgs.every((v) => v !== null)) {
            return {
              ...havingExpr,
              args: exprArgs as Expr[]
            };
          } else {
            // there were invalid exprs
            return null;
          }
        }
      }
    } else {
      // reached the end, so must be valid
      return havingExpr;
    }
  };

  return validateHavingExpr(having);
};

const updateGroupByWhere = (selectionsToDrop: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[], where: Expr | null) => {
  if (where) {
    if (selectionsToDrop.length) {
      const whereReferences: string[] = pluckColumnRefs(where).map(ref => ref.value);
      for (let i = 0; i < selectionsToDrop.length; i++) {
        const maybeAlias = getAnyAliasName(selectionsToDrop[i]);
        // if there is an alias, check if its referenced in the where
        if (maybeAlias && _.some(whereReferences, whereRef => _.isEqual(whereRef, maybeAlias))) {
          return null; // the dropped alias is in the WHERE
        }
      }
    }
    // its probably valid if there are no dropped alias referenced in the where
    return where;
  }
  return null;
};

function VisualGroupBy(props: VisualGroupByProps) {

  const eexpr = props.group;

  const onSelectBasedOn = (v: FunCall) => {
    // we pass in the column that the function is getting applied to in args
    props.onUpdate({...v, args: [eexpr.untyped]});
  };

  const formatAggregateFunctionNameDropDown = (functionName: string, translatedName: string) => {
    const formattedName = getAlternateFunctionName(functionName);
    if (formattedName) {
      return `${formattedName} (${translatedName})`;
    } else {
      return translatedName;
    }
  };

  const formatExpressionEditorFunctionName = (functionName: string, translatedName: string) => {
    const formattedName = getAlternateFunctionName(functionName);
    if (formattedName) {
      return formattedName;
    } else {
      return translatedName;
    }
  };

  const getAlternateFunctionName = (functionName: string) => {
    switch (functionName) {
      case 'date_trunc_y':
      case 'datez_trunc_y':
        return t('by_year');
      case 'date_trunc_ym':
      case 'datez_trunc_ym':
        return t('by_month');
      case 'date_trunc_ymd':
      case 'datez_trunc_ymd':
        return t('by_day');
      default:
        return null;
    }
  };

  const filterScope = (desiredFunctions: string[]) => (
    props.scope.filter((v) => desiredFunctions.includes(v.name))
    // remove the weird duplicate functions that are in scope
    .reduce((accumulator: FunSpec[], current: FunSpec) => {
      if (!accumulator.find((item) => item.name === current.name)) {
        accumulator.push(current);
      }
      return accumulator;
    }, [])
  );

  const getAddGroupByScope = () => {
    // should be at this point but type safety
    if (enableSimpleDateGrouping && isEditable(eexpr)) {
      switch (eexpr.typed.soql_type) {
        case SoQLType.SoQLFixedTimestampT:
        case SoQLType.SoQLFixedTimestampAltT:
          return filterScope(dateTruncationFunctionsFixedTimestamp);
        case SoQLType.SoQLFloatingTimestampT:
        case SoQLType.SoQLFloatingTimestampAltT:
          return filterScope(dateTruncationFunctions);
      }
    }
    return props.scope;
  };

  return (
    <div className="group-by">
      <div className="group-expression">
        <div className='mouseover-wrapper'>
          {shouldShowGroupByFunctionPicker(eexpr) &&
            <div className='group-by-add-function'>
              <AggregateFunPicker
                prompt={t('based_on')}
                scope={getAddGroupByScope()}
                selected={none}
                onSelectFunction={onSelectBasedOn}
                formatFunctionName={formatAggregateFunctionNameDropDown}
              />
            </div>
          }
          <ExpressionEditor
            isTypeAllowed={(st: SoQLType) => true}
            scope={getAddGroupByScope()}
            eexpr={eexpr}
            forceShowSuccess
            columns={props.columns}
            parameters={props.parameters}
            update={props.onUpdate}
            remove={props.onRemove}
            projectionInfo={props.projectionInfo}
            querySucceeded={props.querySucceeded}
            // only pass this in if the ff is enabled
            {...(enableSimpleDateGrouping && { formatFunctionName: formatExpressionEditorFunctionName })}
          />
        </div>
      </div>
    </div>
  );
}

export function removeGroupBy(
  ast: UnAnalyzedAst,
  selectedExpressions: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  scope: Scope,
  dropAtIndex: number,
  columns: ViewColumnColumnRef[]
): Option<UnAnalyzedAst> {
  const aggregateSelections = selectedExpressions.filter(se => containsAggregate(scope, se.untyped.expr));
  return option(ast.group_bys[dropAtIndex])
    .map((groupByToRemove) => {
      const [selectionsToDrop, selectionsToKeep] = _.partition(selectedExpressions, (selectedExpr) => {
        /**
         * There are three cases. Generally, we need to remove the
         * selection if it is selecting an expression in the group by.
         * Note: Aggregate selections are not dropped. This may change later.
         *       Since grouping by aggregate function(s) are not allowed,
         *       groupByToRemove should never be directly associated with
         *       aggregate selections and therefore aggregate selections aren't
         *       dropped. Cases 1 & 2 refer to the selection directly corresponding
         *       to groupByToRemove, if it exists.
         *         example: SELECT 1 as one, count(one) group by one
         *           groupByToRemove `one` is associated with selection `1 as one`
         *         example: SELECT column1, 1 as one, count(column1) group by column1
         *           groupByToRemove `column1` is associated with selection column1
         *
         * calculated column alias case
         * the group by is referring to a selection alias of a calculated column
         * a) if used in any aggregate, don't remove associated selection
         *   example:
         *      SELECT 'John' as name, count(name) as count_name, column1
         *      GROUP BY name, column1
         *   after removing group:
         *      SELECT 'John' as name, count(name)
         *      GROUP BY column1
         * b) if not used in any aggregate, remove the associated selection
         *   if the group is referring to a selection alias of a calculated column
         *   and the alias is not used in an aggregate
         *   example:
         *      SELECT 'John' as name, count(column1)
         *      GROUP by name, column1
         *   after removing group:
         *      SELECT count(column1)
         *      GROUP by column1
         *
         * dataset columns alias case
         * if the group by is referring to a selection alias
         * example:
         *    SELECT lower(`primary_breed`) AS casefolded_breed, count(casefolded_breed)
         *    WHERE `species` = 'Dog'
         *    GROUP BY species, casefolded_breed
         *
         * unaliased case
         * if the group by is just an expression
         * example:
         *    SELECT lower(`species`), count(casefolded_breed)
         *    GROUP BY species, casefolded_breed
         *
         * the expressions in the group by exists */
        const groupByIsColumnRef = isColumnRef(groupByToRemove);
        const selectedExprIsColumnRef = isColumnRef(selectedExpr.untyped.expr);
        const namesMatch = () => _.isEqual((groupByToRemove as ColumnRef).value, selectedExpr.typed.name);
        if (groupByIsColumnRef && namesMatch() && !selectedExprIsColumnRef) {
          // first case
          const groupByInAggregate = _.find(aggregateSelections, (ase) => isSubExpr(groupByToRemove, ase.untyped.expr));
          return _.isUndefined(groupByInAggregate);
        } else if (groupByIsColumnRef && namesMatch()) {
          // second case
          return true;
        } else {
          // third case
          return containsNonAggregatedExpr(groupByToRemove, selectedExpr.untyped.expr, scope);
        }
      });

      const newGroupBys = ast.group_bys.filter((gb, i) => i !== dropAtIndex);

      return {
        ...ast,
        selection: buildSelection(selectionsToKeep.map(({ untyped: expr }) => expr), newGroupBys, columns),
        group_bys: newGroupBys,
        order_bys: dropOrderBys(selectionsToDrop, ast.order_bys),
        having: getValidHaving(selectionsToKeep, newGroupBys, ast.having, scope),
        where: updateGroupByWhere(selectionsToDrop, ast.where)
      };
    });
}

export function updateGroupBy(
  ast: UnAnalyzedAst,
  selectedExpressions: EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[],
  newExpr: Expr,
  updateAtIndex: number,
  scope: Scope
): Option<UnAnalyzedAst> {
  const oldExpr = ast.group_bys[updateAtIndex];
  const [selectionsToDrop, selectionsToKeep] = _.partition(selectedExpressions, selection =>
    isExpressionEqualIgnoringPosition(selection.untyped.expr, oldExpr) ||
    (isColumnRef(oldExpr) && selection.typed.name === oldExpr.value)
  );
  const newGroupBys = replaceAt(ast.group_bys, newExpr, updateAtIndex);

  return some({
    ...ast,
    selection: putGroupingInSelection(buildSelection(selectionsToKeep.map(({ untyped: expr }) => expr), newGroupBys), newExpr),
    group_bys: newGroupBys,
    having: getValidHaving(selectionsToKeep, newGroupBys, ast.having, scope),
    where: updateGroupByWhere(selectionsToDrop, ast.where),
    order_bys: dropOrderBys(selectionsToDrop, ast.order_bys)
  });
}

// Currently, this only works for dataset columns and aliased caculated columns
// TODO: figure out what we want to do for unaliased calculated columns (EN-46634)
function filteredInHaving(
  ast: UnAnalyzedAst,
  dropAtIndex: number
): boolean {
  if (!ast.having) return false;
  const groupByToRemove = ast.group_bys[dropAtIndex];
  if (isColumnRef(groupByToRemove)) {
    const columnsInHaving = getFilteredColumnNames(ast.having);
    return columnsInHaving.includes(groupByToRemove.value);
  }
  return false;
}

interface VisualGroupByListProps {
  ast: UnAnalyzedAst;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  compileAST: CompileAST;
  editableGroupBys: Option<Eexpr<Expr, TypedExpr>[]>;
  projectionInfo: ProjectionInfo;
  querySucceeded: boolean;
  selectedExpressions: Option<EditableExpression<UnAnalyzedSelectedExpression, AnalyzedSelectedExpression>[]>;
  scope: Scope;
  addGroupBy: (picked: PickableColumn) => void;
}

interface VisualGroupByListState {
  showColumnPicker: boolean;
  showRemoveWarning: boolean;
  indexToRemove: number;
}

export default class VisualGroupByList extends React.Component<VisualGroupByListProps, VisualGroupByListState> {
  constructor(props: VisualGroupByListProps) {
    super(props);
    this.state = {
      showColumnPicker: !hasGroupBys(props.ast),
      showRemoveWarning: false,
      indexToRemove: 0
    };
  }

  removeOrShowWarning = (dropAtIndex: number) => {
    const { ast } = this.props;
    if (filteredInHaving(ast, dropAtIndex)) {
      this.setState({ showRemoveWarning: true, indexToRemove: dropAtIndex });
    } else {
      this.removeAt(dropAtIndex);
    }
  };

  removeAt = (dropAtIndex: number) => {
    const { ast, columns, compileAST, scope, selectedExpressions } = this.props;
    selectedExpressions
      .flatMap((selects) => removeGroupBy(ast, selects, scope, dropAtIndex, columns))
      .forEach(astItem => {
        if (!hasGroupBys(astItem)) { this.setState({ showColumnPicker: true }); }
        compileAST(astItem, true);
      });
  };

  updateAt = (updateAtIndex: number, newExpr: Expr) => {
    const { ast, compileAST, selectedExpressions, scope } = this.props;
    selectedExpressions
      .flatMap((selects) => updateGroupBy(ast, selects, newExpr, updateAtIndex, scope))
      .forEach(astItem => compileAST(astItem, true));
  };

  onSelectColumn = (picked: PickableColumn) => {
    this.setState({ showColumnPicker: false });
    this.props.addGroupBy(picked);
  };

  render() {
    const { ast, columns, editableGroupBys, parameters, projectionInfo, scope } = this.props;
    const explicitAliases = ast.selection.exprs.flatMap(use => use.name ? [use.name.name] : []);
    const columnsNotUsedAsExplicitAliases = columns.filter(vccr => !explicitAliases.includes(vccr.ref.value));
    const help = (<p className="help-message forge-typography--body2" dangerouslySetInnerHTML={{ __html: t('help') }}></p>);//
    const groupBys = editableGroupBys.map<JSX.Element[] | null>(
      (editableExpressions) => {
        if (editableExpressions.length) {
          return editableExpressions.map((group, i) => (
              <VisualGroupBy
                key={i}
                group={group}
                columns={columnsNotUsedAsExplicitAliases}
                parameters={parameters}
                scope={scope}
                onUpdate={(newExpr) => this.updateAt(i, newExpr)}
                onRemove={() => this.removeOrShowWarning(i)}
                projectionInfo={projectionInfo}
                querySucceeded={this.props.querySucceeded} />)
          );
        } else {
          return null;
        }
      }
    ).orNull;

    return (
      <div className="vee-expr-container vee-gray-bg vee-group-aggregate vee-group-by">
        <SubtitleWithHelper className="group-aggregate-label group-by-label" title={t('group_by')} help={help} />
        {groupBys}
        {this.state.showColumnPicker &&
          (
            <div className="add-expr group-by-blank-state">
              <div className="column-picker-container">
                <ColumnPicker
                  className="btn btn-default add-expr-column-picker"
                  prompt={t('select_column')}
                  columns={columnsNotUsedAsExplicitAliases}
                  selected={none}
                  projectionInfo={projectionInfo}
                  onSelect={this.onSelectColumn} />
                <RemoveNode onClick={() => this.setState({ showColumnPicker: false })} />
              </div>
            </div>
          )
        }
        <ForgeButton className="add-more" onClick={() => this.setState({ showColumnPicker: true })}>
          <button type="button">
            <ForgeIcon name="add" />
            {t('add')}
          </button>
        </ForgeButton>
        <RemoveGroupModal
          isOpen={this.state.showRemoveWarning}
          onCancel={() => this.setState({ showRemoveWarning: false })}
          onDeleteGroup={() => this.removeAt(this.state.indexToRemove)}
        />
      </div>
    );
  }
}
